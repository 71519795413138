
import Layout from "./Layout/Layout"
import 'react-toastify/dist/ReactToastify.css';


const App = () => {
  return (
    <>
      <Layout />
    </>
  )
}
export default App