

const Header = () => {
  return (
    <>

      <div className="header">
        <p className=' topheading sm:text-[40px] text-center text-[30px]
          font-semibold text-[#7faa2c]'>Study In Ireland By Race Educare</p>
      </div>
    </>
  )
}
export default Header