import { ToastContainer } from "react-toastify"
import Router from "../Routers/Router"




const Layout = () => {
  return (
    <>
      <Router />
      <ToastContainer />
    </>

  )
}
export default Layout